import React from "react"
import { Helmet } from "react-helmet";
import { Document } from "../Classes/Document";
import Banner from "../components/Banner/Banner";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Layout from "../components/Layout/layout"
import DocumentPage from "../Views/Website/Document/Document";
import ThumbnailImg from "../assets/IMG/PrimeHR-Devices.png";
import ExcelIcon from "../assets/IMG/Icon/ExcelIcon.png";
import WordIcon from "../assets/IMG/Icon/WordIcon.png";
import { Package } from "../Classes/Package";
import { StopWords } from "../Data/StopWords";

const DocsPage: React.FC = (props: any) => {
  const Document: Document = props.pageContext.Document;
  const Packages: Package[] = props.pageContext.Packages;
  const type = Document && Document.Icon && Document.Icon.split(" ")[1] ? Document.Icon.split(" ")[1].split("-")[Document.Icon.split(" ")[1].split("-").length - 1] : "";

  const GetKeyWords = () => {
    var x, y, word, stop_word, regex_str, regex;
    let cleansed_string = Document.Description;
    let stop_words = StopWords;
    let words = cleansed_string.match(/[^\s]+|\s+[^\s+]$/g)

    if (words)
      for (x = 0; x < words.length; x++) {
        for (y = 0; y < stop_words.length; y++) {
          word = words[x].replace(/\s+|[^a-z]+/ig, "");
          stop_word = stop_words[y];

          if (word.toLowerCase() == stop_word) {
            regex_str = "^\\s*" + stop_word + "\\s*$";
            regex_str += "|^\\s*" + stop_word + "\\s+";
            regex_str += "|\\s+" + stop_word + "\\s*$";
            regex_str += "|\\s+" + stop_word + "\\s+";
            regex = new RegExp(regex_str, "ig");
            cleansed_string = cleansed_string.replace(regex, " ");
          }
        }
      }
    return cleansed_string.replace(/^\s+|\s+$/g, "");
  }

  return (
    <Layout>
      {Document ? <>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>PrimeHR :: {Document.Title}</title><meta charSet='utf-8' />
          <meta property="og:description" content={`${Document.Description}`} />
          <meta property="og:image" content={Document.Thumbnail[0] ? Document.Thumbnail[0] : type === "excel" ? ExcelIcon : type === "word" ? WordIcon : ThumbnailImg} />
          <meta property="og:title" content={`PrimeHR :: ${Document.Title}`} />
          <meta property="og:site_name" content="PrimeHR" />
          <meta property="og:url" content={`${props.location.href}`} />
          <meta name="keywords" content={GetKeyWords()}></meta>
        </Helmet>

        <Banner />
        <Breadcrumb Trail={[
          { To: "/Packages", Text: "Packages" },
          { To: `/Packages/${Document.Package.Title}`, Text: Document.Package.Title },
          { To: `/Documents`, Text: "Documents" },
          { To: ``, Text: Document.Title }
        ]} />
        <DocumentPage Packages={Packages} Document={Document} location={props.location.href} />
      </> : <></>}
    </Layout>
  );
};

export default DocsPage;