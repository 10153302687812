import React, { useContext, useEffect, useState } from 'react';
import { Document as DocProps, Rating } from '../../../Classes/Document';
import { API_BaseURL, API_BronzeDownload, API_DeleteReview, API_SaveRating } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import * as styles from './Document.module.scss';
import { Package } from '../../../Classes/Package';
import { UserContext } from '../../../Context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft, faArrowRight, faCloudDownloadAlt, faSpinner, faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { Loading } from '../../../components/Loading/Loading';
import { Link, navigate } from 'gatsby';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";

interface Props {
  Document: DocProps;
  location: string;
  Packages: Package[];
}

const DocumentPage: React.FC<Props> = ({ Document, location, Packages }) => {
  const { User } = useContext(UserContext);
  const [doc, setDocument] = useState<DocProps>(Document);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [moreFrom, setMoreFrom] = useState<DocProps[]>([] as DocProps[]);
  const [moreFromSection, setMoreFromSection] = useState<DocProps[]>([] as DocProps[]);
  const [rating, setRating] = useState<Rating>({} as Rating);
  const [slide, setSlide] = useState<number>(0);
  const [copied, setCopied] = useState<boolean>(false);
  const [FileDownload, setFileDownload] = useState<boolean>(false);
  const rates: number[] = [1, 2, 3, 4, 5];
  const shareURL: string = location;

  useEffect(() => {
    Init();
  }, [Document])

  useEffect(() => {
    if (User.ID && doc.ID) {
      let rating: Rating | undefined = doc.Ratings.find(r => r.UserID === User.ID);
      if (rating)
        setRating(rating);
    }
  }, [User, doc])

  const Init = () => {
    GetData();
  }

  const SaveRating = async (e: any) => {
    e.preventDefault();
    setSaveLoading(true);
    const data: Rating = JSON.parse(JSON.stringify(rating));
    data.DocID = doc.ID;
    data.UserID = User.ID;
    await Fetch(API_SaveRating, data).then(async (r: Rating) => {
      if (r.ID) {
        setRating(r);
      }
      setSaveLoading(false);
    });
  }

  const GetData = () => {
    let pack = Packages.find(p => p.ID === doc.PackageID);

    if (pack) {
      setMoreFrom(pack.Documents);

      var sectionDocs: DocProps[] = [] as DocProps[];
      Packages.map(p => {
        p.Documents.filter(s => s.SectionID === doc.SectionID).map((d) => {
          sectionDocs.push(d)
        })
      })

      setMoreFromSection(sectionDocs);
    } else
      navigate("/");
  }

  const shuffle = (array: any[]) => {
    var currentIndex = array.length, randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const GetRating = (Rating: Rating) => {
    return ((100 / 5) * Rating.Score);
  }

  const DeleteRating = async () => {
    let data: Rating = JSON.parse(JSON.stringify(rating));
    setSaveLoading(true);
    await Fetch(`${API_DeleteReview}`, data.ID).then((success: boolean) => {
      if (success) {
        setRating({} as Rating);
        GetData();
      }
    });
    setSaveLoading(false);
  }

  const Copy = async () => {
    await navigator.clipboard.writeText(shareURL);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000)
  }

  const Download = async () => {
    setFileDownload(true);

    let data: DocProps = {
      ID: doc.ID,
      FileTitle: doc.FileTitle,
      Parameters: doc.Parameters,
      Logo: User.Logo,
      Company: User.Company
    } as DocProps;

    await Fetch(`${API_BronzeDownload}`, data).then((res: DocProps) => {
      setDocument({ ...doc, Downloads: res.Downloads, File: res.File });
      var sampleArr = base64ToArrayBuffer(res.File);
      saveByteArray(doc, sampleArr);
    });

    setFileDownload(false);
  }

  const saveByteArray = (Doc: DocProps, byte: BlobPart) => {
    var blob = new Blob([byte], { type: `application/${Doc.File.split(".")[Doc.File.split(".").length - 1]}` });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = Doc.FileTitle;
    link.download = fileName;
    link.click();
  };

  const base64ToArrayBuffer = (base64: string) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  return (<>
    {doc.ID ? <div className={styles.Packages}>
      <div className={styles.Package}>
        <div className={styles.PackageInfo}>
          <div className={styles.PackageInfoInner}>
            <h1>{doc.Title}</h1>
            {doc.MainThumbnail[0] && doc.MainThumbnail.length ? <div className={styles.Images}>
              {
                doc.MainThumbnail.map((thumbnail, i) => {
                  if (i < 2)
                    return <img loading={"lazy"} src={`${API_BaseURL.replace("/api/", "")}${thumbnail}`} />
                })
              }
            </div> : <></>}
            <pre>{doc.Description}</pre>

            {doc.Parameters.length ? <div className={styles.Parameters}>
              <p>This Document comes with some parameters you need to fill in!</p>

              <p>
                By creating a <Link to={"/Packages/Bronze"}>Bronze Account</Link>, you get access to our <b>Custom Forms</b> to easily fill in each of these parameters. Including <b>Customer Branding</b> when you add your logo and company name!
              </p>

              {doc.PackageID === 1 ? <ul>
                {doc.Parameters.map(param => {
                  return <li><p>{param.Label}</p></li>
                })}
              </ul> : <></>}
            </div> : <></>}

            <p><Link to="/Packages">Click Here</Link> To Read More About Our Subscription Packages</p>
            {User.ID ? <></> : <p>Don't have an account? <Link to="/Register">Register</Link> Now to begin downloading your FREE Documents</p>}
          </div>
        </div>

        {doc.Ratings.length || User.ID ? <div className={styles.Ratings}>
          {User.ID ?
            <form className={styles.RatingsForm}>
              {saveLoading ? <div className={styles.Loading}><Loading /></div> : <>
                <div className={styles.RatingsFormStars}>
                  {
                    rates.map(i => {
                      return <button key={`my-rate-${i}`} type="button" onClick={() => setRating({ ...rating, Score: i })}>
                        <p>{i}</p>
                        <i>
                          <FontAwesomeIcon icon={rating.Score >= i ? faSolidStar : faStar} />
                        </i>
                      </button>
                    })
                  }
                </div>
                <label>Comment:</label>
                <textarea value={rating.Comment} onChange={(e) => setRating({ ...rating, Comment: e.target.value })} placeholder="Leave your comment here..."></textarea>
                {rating.ID ? <button type="button" onClick={() => DeleteRating()}>Remove Review</button> : <></>}
                <button type="submit" onClick={(e) => SaveRating(e)}>Save</button>
              </>}
            </form>
            : <></>}

          {doc.Ratings.length ? <>
            <div className={styles.RatingsList}>
              <div className={styles.RatingsOverall}>
                {
                  rates.reverse().map(i => {
                    return <div className={styles.RatingsOverallItem}>
                      <span>{i}</span>
                      <div className={styles.RatingsOverallItemBar}>
                        <div className={styles.RatingsOverallItemBarInner} style={{ width: ((100 / doc.Ratings.length) * doc.Ratings.filter(r => r.Score === i).length) + "%" }}></div>
                      </div>
                      <span>{doc.Ratings.filter(r => r.Score === i).length}</span>
                    </div>
                  })
                }
              </div>

              <div className={styles.RatingsListCarousel}>
                {slide ? <button className={styles.RatingsListButton} onClick={() => setSlide(slide - 1)}>
                  <i>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </i>
                </button> : <></>}
                {slide !== (doc.Ratings.length - 1) ? <button className={`${styles.RatingsListButton} ${styles.Right}`} onClick={() => setSlide(slide + 1)}>
                  <i>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </i>
                </button> : <></>}

                <div className={styles.RatingsListCarouselInner}>
                  {
                    shuffle(doc.Ratings).map((rating, ind) => {
                      if (ind === slide)
                        return <div key={`rating-${rating.id}`} className={`${styles.RatingsListItem}`}>
                          <div className={styles.Rating}>
                            <div className={styles.RatingInner}>
                              <div className={styles.RatingStars}>
                                {rates.map(i => { return <i key={`star-${i}`}><FontAwesomeIcon icon={faStar} /></i> })}
                              </div>
                              <div className={styles.RatingPerc} style={{ width: `${GetRating(rating)}%` }}>
                                <div className={styles.RatingStars}>
                                  {rates.map(i => { return <i key={`starsolid-${i}`}><FontAwesomeIcon icon={faSolidStar} /></i> })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <h2>{rating.Author}</h2>
                          <p>{rating.Comment}</p>
                        </div>
                    })
                  }
                </div>
              </div>
            </div>
          </> : <div className={styles.Message}><h1>No Reviews Yet!</h1><p>Leave your review now, it could help someone else!</p></div>}
        </div> : <></>}
      </div>


      <div className={styles.Side}>
        {doc.Package.isFree ? <button onClick={() => Download()}>
          <i>
            <FontAwesomeIcon icon={FileDownload ? faSpinner : faCloudDownloadAlt} spin={FileDownload} />
          </i>
          {FileDownload ? "Downloading..." : "Download"}
        </button> : <></>}

        <div className={`${styles.Share} ${copied ? styles.Active : ""}`}>
          <input value={shareURL} type="text" readOnly={true} />
          <button onClick={() => Copy()}>{copied ? "Copied!" : "Copy"}</button>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </div>
        </div>

        <div className={styles.SideInfo}>
          <h4>Found This Document Useful?</h4>
          <p>If you found this Document or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Bronze"}>Bronze</Link>, <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

          <Link to="/Register" className={styles.Button}>
            Create an Account
          </Link>
        </div>

        {doc.Package ? <div className={styles.MoreFrom}>
          {moreFrom.filter(d => d.ID !== doc.ID).filter((d, i) => i < 7).length ? <div className={styles.MoreFromInner}>
            <h1>More From {doc.Package.Title}</h1>
            <ul>
              {
                moreFrom.filter(d => d.ID !== doc.ID).filter((d, i) => i < 7).map((d, i) => {
                  return <li><Link to={`/Document/${d.Title.replace(/ /g, "-")}`}>{d.Title}</Link></li>
                })
              }
            </ul>
          </div> : <></>}

          {moreFromSection.filter(d => d.ID !== doc.ID).filter((d, i) => i < 7).length ? <div className={styles.MoreFromInner}>
            <h1>More From {doc.Section.Title}</h1>
            <ul>
              {
                moreFromSection.filter(d => d.ID !== doc.ID).filter((d, i) => i < 7).map((d, i) => {
                  return <li><Link to={`/Document/${d.Title.replace(/ /g, "-")}`}>{d.Title}</Link></li>
                })
              }
            </ul>
          </div> : <></>}
        </div> : <></>}
      </div>
    </div> : <></>}
  </>);
};

export default DocumentPage;
