// extracted by mini-css-extract-plugin
export var Active = "Document-module--Active--46c8a";
export var Button = "Document-module--Button--6265d";
export var Images = "Document-module--Images--d862f";
export var Loading = "Document-module--Loading--f8c35";
export var Message = "Document-module--Message--5b7e7";
export var MoreFrom = "Document-module--MoreFrom--37116";
export var MoreFromInner = "Document-module--MoreFromInner--77173";
export var Package = "Document-module--Package--076d6";
export var PackageDoc = "Document-module--PackageDoc--e7081";
export var PackageInfo = "Document-module--PackageInfo--107d3";
export var PackageInfoInner = "Document-module--PackageInfoInner--77a55";
export var PackageInner = "Document-module--PackageInner--61806";
export var Packages = "Document-module--Packages--1f80d";
export var Parameters = "Document-module--Parameters--0607b";
export var Rating = "Document-module--Rating--ae217";
export var RatingInner = "Document-module--RatingInner--146cb";
export var RatingPerc = "Document-module--RatingPerc--5f241";
export var RatingStars = "Document-module--RatingStars--6fb3f";
export var Ratings = "Document-module--Ratings--c32ed";
export var RatingsForm = "Document-module--RatingsForm--3b645";
export var RatingsFormStars = "Document-module--RatingsFormStars--e3d31";
export var RatingsList = "Document-module--RatingsList--10a27";
export var RatingsListButton = "Document-module--RatingsListButton--f239b";
export var RatingsListCarousel = "Document-module--RatingsListCarousel--df05f";
export var RatingsListCarouselInner = "Document-module--RatingsListCarouselInner--b8c54";
export var RatingsListItem = "Document-module--RatingsListItem--801e3";
export var RatingsOverall = "Document-module--RatingsOverall--aa098";
export var RatingsOverallItem = "Document-module--RatingsOverallItem--040f1";
export var RatingsOverallItemBar = "Document-module--RatingsOverallItemBar--41155";
export var RatingsOverallItemBarInner = "Document-module--RatingsOverallItemBarInner--b3dcf";
export var Right = "Document-module--Right--6b18c";
export var Share = "Document-module--Share--5d15e";
export var Side = "Document-module--Side--afb4f";
export var SideInfo = "Document-module--SideInfo--69c65";
export var Social = "Document-module--Social--88664";